<template>
  <div class="flex justify-center items-center" style="height: 100vh;">
    <el-empty description="你的页面走丢了～" :image-size="400" :image="img">
      <el-button type="primary" plain icon="el-icon-back" @click="$router.push('/')">返回首页</el-button>
    </el-empty>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {
      img: require('@/assets/404.jpg')
    }
  }
}
</script>